import GET_ARTICLES from "../class/getArticles";
import Swiper from "swiper/bundle";

const [ w, d, mq ] = [window, document, window.matchMedia( "(max-width: 900px)" ) ];
const domReady = TPL => {
  new BLOCKS;
};

class BLOCKS {
  constructor() {
    this.mainvisual();
    this.news();
  }
  
  mainvisual () {
    let Mv = document.querySelector('.mainvisual');
    window.addEventListener('load', function() {
      Mv.classList.add('loaded');
    });

    //メインスライダー
    const mvSlide = new Swiper('.mv_slide[data-plugin="mainSlider"]',{
      loop: true,
      speed: 1200,
      allowTouchMove: false,
      autoplay:{
        delay: 6000,
        disableOnInteraction: false,
        waitForTransition: false
      },
      pagination: {
        el: ".mv-pager",
        clickable: true,
      },
    });
  }

  news () {
    //NEWS
    const getArticle = new GET_ARTICLES('.block-news .news_list');
    getArticle.makeItem = (item,content) => `
    <li>
      <a href="${item.href}">
        <p class="date">${item.date}</p>
        <p class="cate">${item.category.name}</p>
        <p class="ttl${(item.is_new)? ' is-new': ''}">${item.title}</p>
        <svg class="icon icon_arrow"><use href="/assets/svg/sprite.symbol.svg#icon_arrow"></use></svg>
      </a>
    </li>`;
    // getArticle.makeItem = (item) => console.log(item);
    getArticle.render();

    // カテゴリー
    const getCategories = this.fetchCategories('news');
    const categoryContainer = d.querySelectorAll('.block-news [data-plugin="getCategories"]');
    getCategories.then( cats => {
      let cat = {
        all: {
          id: 'all', href: '/news/?mode=list', name: 'ALL', turn: 0
        }
      }
      cats = [ cat.all, ...cats, cat.other ];
      const makeHTML = cat => `<li><a class="is-${cat.id}" href="${cat.href}">${cat.name}</a></li>`;
      categoryContainer.forEach( obj => {
        obj.innerHTML = '';
        cats.forEach( cat => obj.insertAdjacentHTML('beforeend', makeHTML(cat) ) );
      });
    });
  }
    
  fetchCategories (content) {
    return fetch(`/assets/api/getCategories/?content=${content}`)
      .then(r => r.json())
      .catch(e => { throw new Error(e)});
  }

  fetchArticles (opt){
    return fetch(`/assets/api/getArticles/?${ new URLSearchParams(opt)}`)
    .then(r => r.json())
    .catch(e => { throw new Error(e)});
  }
}

export default function HOME () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
}